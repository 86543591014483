
import Http from './Http'
import Security from './Security';

export default {
    async getCustomer(customer_id) {
        return await Http.get(`customers/${customer_id}`)
    },
    async getCustomerOptIns(customer_id) {
        return await Http.get(`customers/optins?customer_id=${customer_id}`);
    },
    async getCustomers(data) {
        return await Http.get(`customers?${data}`)
    },
    async getCustomersByFilter(data) {
        return await Http.get(`/customers/filter?${data}`)
    },
    async addCustomer(data) {
        let sanitizedData = Security.sanitizeObject(data)

        return await Http.post('customers', sanitizedData)
    },
    async updateCustomer(customer_id, data) {
        let sanitizedData = Security.sanitizeObject(data)

        return await Http.patch(`/customers/${customer_id}`, sanitizedData)
    },
    async updatePhone(phone_id, data) {
        return await Http.patch(`/customers/phone/${phone_id}`, data)
    },
    async primaryEmail(data) {
        return await Http.post(`/customers/email/confirm`, data)
    },
    async updateAddress(address_id, data) {
        let sanitizedData = Security.sanitizeObject(data)

        return await Http.patch(`/customers/address/${address_id}`, sanitizedData)
    },
    async addAddress(data) {
        let sanitizedData = Security.sanitizeObject(data)

        return await Http.post(`/customers/address`, sanitizedData)
    },
    async addPhone(data) {
        return await Http.post(`/customers/phone`, data)
    },
    async removeAddress(address_id) {
        return await Http.delete(`/customers/address/${address_id}`)
    },
    async removePhone(phone_id) {
        return await Http.delete(`/customers/phone/${phone_id}`)
    },
    async getUserStatuses() {
        return await Http.get(`/customers/statuses`)
    },
    async changeCRMPassword(data) {
        return await Http.patch(`/customers/admin/passwordChange`, data)
    },
    async verifyEmail(data) {
        return await Http.patch(`/customers/email/verify`, data)
    },
    async getCustomersCountries() {
        return await Http.get(`/customers/address/countries`)
    },
    async getCustomersRequests(data) {
        return await Http.get(`/customers/requests?${data}`)
    },
    async resolveCustomersRequest(request_id, data) {
        return await Http.patch(`/customers/requests/${request_id}`, data)
    },
    async deleteCustomer(customer_id) {
        return await Http.delete(`/customers/${customer_id}`)
    },
    async createCustomerFromTicketingData(data) {
        return await Http.post(`/customers/admin/createFromTicketing`, data)
    },
    async addRequest(data) {
        return await Http.post(`/customers/requests`, data)
    },
    async getCustomersGroups() {
        return await Http.get(`/customers/groups`)
    },
    async getGroups(data) {
        return await Http.get(`/customers/groups`, data)
    },
    async getGroupsDetails(group_id) {
        return await Http.get(`/customers/groups?group_id=${group_id}`)
    },
    async addGroup(data) {
        return await Http.post(`/customers/groups`, data)
    },
    async deleteGroup(group_id) {
        return await Http.delete(`/customers/groups/${group_id}`)
    },
    async updateGroup(group_id, data) {
        return await Http.patch(`/customers/groups/${group_id}`, data)
    },
    async getMembers(group_id) {
        return await Http.get(`/customers/groups/${group_id}/members`)
    },
    async deleteMember(group_id, customer_id) {
        return await Http.delete(`/customers/groups/${group_id}/${customer_id}`)
    },
    async addMembers(group_id, emails) {
        return await Http.post(`/customers/groups/${group_id}/emails`, emails)
    },
    async getCustomersCollectibles(customer_id) {
        return await Http.get(`customers/collectibles?customer_id=${customer_id}`);
    },
    async getNftPacks() {
        return await Http.get(`/customers/nftPacks`)
    },
    async getNftPackDetails(pack_id) {
        return await Http.get(`/customers/nftPacks/${pack_id}`)
    },
    async getLoginAttempts(customer_id) {
        return await Http.get(`/customers/lastLoginAttempts?customer_id=${customer_id}`);
    },
    async  getCompanies(data){
        return await Http.get(`/business/companies?/${data}`)
    },
    async  editCompany(company_id,data){
        return await Http.post(`/business/companies/${company_id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    } ,
    async  addCompany(data){
        return await Http.post(`/business/companies`,data ,{
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    } ,
    async deleteCompany(company_id){
        return await Http.delete(`/business/companies/${company_id}`)
    } ,
    async getBusinessMembers(company_id){
        return await Http.get(`business/companies/members?/${company_id}`)
    },
    async addBusinessMember(data) {
        return await Http.post(`/business/companies/members`, data)
    },
    async deleteBusinessMembers(company_id,customer_id){
        return await Http.delete(`/business/companies/members/${company_id}/${customer_id}`)
    },
    async verifiedUser(data, customer_id){
        return await Http.patch(`/customers/verified/${customer_id}`, data)
    }

}


